import Vue from 'vue';

export default {
  get: async () => Vue.http.get('users/manage')
    .then((res) => res.json()),

  getById: async (id) => Vue.http.get(`users/manage/${id}`)
    .then((res) => res.json()),

  update: async (model, id) => Vue.http.put(`users/manage/${id}`, model)
    .then((res) => res.json()),

  save: async (model) => Vue.http.post('users/manage', model)
    .then((res) => res.json()),

  delete: async (id) => Vue.http.delete(`users/manage/${id}`)
    .then((res) => res.json()),
};
