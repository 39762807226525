<template>
  <div>
    <b-row class="my-4">
      <b-col cols="12">
        <h3 class="center text-info float-left text-uppercase">
          {{ $t('sections.users') }}
        </h3>

        <router-link :to="{ name: 'manage-users-create' }">
          <b-button size="sm" class="ml-3" variant="success">
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-plus-circle-fill"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5
                4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5
                0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"
              />
            </svg>
            {{ $t('usersManagement.create' )}}
          </b-button>
        </router-link>
      </b-col>
    </b-row>

    <b-row class="mb-3">
      <b-col lg="8" sm="12" class="mb-sm-2">
        <b-input-group>
          <b-form-input
            v-model="filter"
            type="search"
            :placeholder="$t('inputs.typeSearch')"
          />

          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">
              {{ $t('buttons.clear') }}
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>

      <b-col lg="4" sm="12" class="text-right">
        <p class="mb-0">{{ $t('usersManagement.registers', { num: totalRows }) }}</p>
      </b-col>
    </b-row>

    <b-table
      show-empty
      responsive
      bordered
      striped
      hover
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      :busy="loading"
      :items="data"
      :fields="fields"
      :emptyText="$t('messages.emptyTable')"
      :emptyFilteredText="$t('messages.emptyFilteredTable')"
      @filtered="onFiltered"
    >
      <template v-slot:table-busy>
        <div class="text-center text-danger my-2 align-items-center d-flex flex-column">
          <b-spinner class="align-middle flex-direction-column mb-3"></b-spinner>
          <strong>{{ $t('messages.loading') }}...</strong>
        </div>
      </template>

      <template v-slot:cell(isActive)="data">
        <p class="text-center m-0">
          <b-icon v-if="data.value" icon="check-circle-fill" variant="success" />
          <b-icon v-else icon="x-circle-fill" variant="danger" />
        </p>
      </template>

      <template v-slot:cell(createdAt)="data">
        <p class="text-center m-0">
          {{ formatDate(data.value) }}
        </p>
      </template>

      <template v-slot:cell(actions)="data">
        <p class="text-center m-0">
          <b-button
            size="sm"
            variant="info"
            @click="$router.push({ name: 'manage-users-edit', params: { id: data.item.id } })"
          >
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-pencil-square"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5
                0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0
                0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0
                0 0 .196-.12l6.813-6.814z"
              />
              <path
                fill-rule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0
                0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0
                1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
              />
            </svg>
            {{ $t('buttons.edit') }}
          </b-button>

          <span class="mx-1" />

          <b-button
            size="sm"
            variant="danger"
            @click="deleteModal = true; deleteId = data.item.id"
          >
            <b-icon icon="trash" />
            {{ $t('buttons.delete') }}
          </b-button>
        </p>
      </template>
    </b-table>

    <b-row class="text-center mt-4" v-if="totalRows > perPage">
      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
        />
      </b-col>
    </b-row>

    <b-modal
      centered
      v-model="deleteModal"
      @ok="onDeleteUser"
      :ok-title="$t('buttons.delete').toUpperCase()"
      :cancel-title="$t('buttons.cancel').toUpperCase()"
      ok-variant="danger"
    >
      <div class="d-block">{{ $t('messages.deleteConfirmMessage') }}</div>
    </b-modal>
  </div>
</template>

<script>
import * as Sentry from '@sentry/browser';
import { DateTime } from 'luxon';
import UsersManage from '../../../../services/UsersManage';
import Toast from '../../../../assets/js/toast';

export default {
  data() {
    const data = {
      data: [],
      filter: '',

      totalRows: 0,
      loading: true,
      deleteModal: false,
      deleteId: null,
      currentPage: 1,
      perPage: 20,

      fields: [
        { key: 'name', label: this.$t('inputs.name'), sortable: true },
        { key: 'email', label: 'E-mail', sortable: true },
        { key: 'isActive', label: this.$t('inputs.active'), sortable: true },
        { key: 'createdAt', label: this.$t('inputs.createdAt'), sortable: true },
        { key: 'actions', label: this.$t('inputs.action') },
      ],
    };

    return data;
  },

  beforeMount() {
    this.onLoad();
  },

  methods: {
    async onLoad() {
      try {
        this.data = await UsersManage.get();

        this.totalRows = this.data.length;
      } catch (e) {
        Sentry.captureException(e);
      } finally {
        this.loading = false;
      }
    },

    async onDeleteUser() {
      try {
        this.loading = true;

        await UsersManage.delete(this.deleteId);

        Toast.success(this, 'messages.removeSuccess');
      } catch (e) {
        Sentry.withScope((scope) => {
          scope.setExtra('deleteId', this.deleteId);
          Sentry.captureException(e);
        });

        Toast.error(this, e);
      } finally {
        this.deleteId = null;
        this.onLoad();
      }
    },

    onFiltered() {
      this.currentPage = 1;
    },

    formatDate(dt) {
      try {
        return DateTime
          .fromISO(dt)
          .setLocale(this.$i18n.locale)
          .toFormat('ff');
      } catch (e) {
        return dt;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
</style>
